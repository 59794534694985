<template>
  <div class="lightbox-subscription">
    <div class="lightbox-subscription-create" v-if="!editMode">
      <SelectInput
        ref="subscriptionDuration"
        inputName="subscriptionDuration"
        placeholder="Select subscription duration"
        inputClass="dashboard"
        :options="monthOptions"
        v-model="newSubscription.months"
      />

      <SelectInput
        ref="subscriptionType"
        inputName="subscriptionType"
        placeholder="Select subscription Type"
        inputClass="dashboard"
        :options="subscriptionTypeOptions"
        v-model="newSubscription.type"
      />

      <SelectInput
        ref="subscriptionStatus"
        inputName="subscriptionStatus"
        placeholder="Select payment status"
        inputClass="dashboard"
        :options="statusOptions"
        v-model="newSubscription.status"
      />

      <TextInput
        ref="subscriptionInvoice"
        placeholder="Invoice Number"
        inputName="invoiceNumber"
        inputType="text"
        inputClass="dashboard"
        v-model="newSubscription.invoiceNumber"
      />

      <MercalaButton
        v-on:button-event="createNewSubscription()"
        label="Create"
        type="dashboard-ok"
      />
    </div>
    <table v-if="!editMode">
      <thead>
        <tr>
          <th
            v-for="(row, index) in table.headers"
            :key="index"
            v-on:click="setSorting(row.sorting)"
          >
            {{ row.title }}
            <img
              v-if="row.title != ''"
              src="../../assets/images/sorting_arrow.svg"
              :class="showSorting(row.sorting)"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subscription in subscriptions" :key="subscription.id">
          <td>{{ formatReadableDate(subscription.start_date) }}</td>
          <td>{{ formatReadableDate(subscription.end_date) }}</td>
          <td>{{ subscription.type }}</td>
          <td>{{ subscription.invoice_number }}</td>
          <td>
            <span :class="statusClasses(subscription.status)">
              {{ subscription.status }}
            </span>
          </td>
          <td class="tools">
            <button v-on:click="openEditMode(subscription)">
              <font-awesome-icon :icon="['fa', 'pen']" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div :class="editModeClasses()">
      <h3>Editing subscription</h3>

      <DatePicker
        ref="updateSubscriptionStartDate"
        inputName="updateSubscriptionStartDate"
        placeholder="Start Date"
        inputClass="dashboard"
        v-model="editSubscription.start_date"
      />

      <DatePicker
        ref="updateSubscriptionEndDate"
        inputName="updateSubscriptionEndDate"
        placeholder="End Date"
        inputClass="dashboard"
        v-model="editSubscription.end_date"
      />

      <SelectInput
        ref="updateSubscriptionType"
        inputName="subscriptionType"
        placeholder="Select subscription Type"
        inputClass="dashboard"
        :options="subscriptionTypeOptions"
        v-model="editSubscription.type"
      />

      <SelectInput
        ref="updateSubscriptionStatus"
        inputName="subscriptionStatus"
        placeholder="Select payment status"
        inputClass="dashboard"
        :options="statusOptions"
        v-model="editSubscription.status"
      />

      <TextInput
        ref="updateSubscriptionInvoice"
        placeholder="Invoice Number"
        inputName="invoiceNumber"
        inputType="text"
        inputClass="dashboard"
        v-model="editSubscription.invoice_number"
      />

      <div class="lightbox-subscription-edit-tools">
        <MercalaButton
          v-on:button-event="closeEditMode()"
          label="Stop editing"
          type="dashboard-cancel"
        />

        <MercalaButton
          v-on:button-event="updateSubscription()"
          label="Update subscription"
          type="dashboard-ok"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/form/DatePicker";
import SelectInput from "@/components/form/SelectInput";
import MercalaButton from "@/components/form/MercalaButton";
import AngelaAPI from "@/utils/AngelaAPI";
import TextInput from "@/components/form/TextInput";

import Helpers from "@/utils/Helpers";
import { EventBus } from "@/utils/EventBus.js";
const moment = require("moment");

export default {
  name: "LightboxSubscription",
  components: {
    SelectInput,
    MercalaButton,
    TextInput,
    DatePicker,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      editMode: false,
      newSubscription: {
        months: "",
        status: "",
        type: "",
        invoiceNumber: "",
      },
      editSubscription: {
        id: "",
        start_date: "",
        end_date: "",
        type: "",
        status: "",
        invoice_number: "",
      },
      monthOptions: [
        { name: "1 month", val: 1 },
        { name: "2 months", val: 2 },
        { name: "3 months", val: 3 },
        { name: "4 months", val: 4 },
        { name: "5 months", val: 5 },
        { name: "6 months", val: 6 },
        { name: "7 months", val: 7 },
        { name: "8 months", val: 8 },
        { name: "9 months", val: 9 },
        { name: "10 months", val: 10 },
        { name: "11 months", val: 11 },
        { name: "1 year", val: 12 },
      ],
      statusOptions: [
        { name: "pending", val: "pending" },
        { name: "paid", val: "paid" },
      ],
      subscriptionTypeOptions: [
        { name: "standard", val: "standard" },
        { name: "trial", val: "trial" },
      ],
      table: {
        headers: [
          { title: "START DATE", sorting: "start_date" },
          { title: "END DATE", sorting: "end_date" },
          { title: "TYPE", sorting: "type" },
          { title: "INVOICE NUMBER", sorting: "invoice_number" },
          { title: "STATUS", sorting: "status" },
          { title: "TOOLS", sorting: "tools" },
        ],
      },
    };
  },
  computed: {
    subscriptions() {
      if (this.$props.user) {
        return this.$props.user.subscriptions;
      }
      return [];
    },
  },
  mounted() {
    this.$emit("setLightboxInfo", {
      title: "User Subscriptions",
    });
  },
  methods: {
    formatReadableDate(datetime) {
      return moment(datetime).format("DD-MM-YYYY HH:mm");
    },
    setSorting(field) {
      Helpers.setSorting(this, field);
    },
    showSorting(field) {
      return Helpers.showSorting(this, field);
    },
    openEditMode(subscriptionToEdit) {
      this.editSubscription.id = subscriptionToEdit.id;
      this.editSubscription.start_date = subscriptionToEdit.start_date;
      this.editSubscription.end_date = subscriptionToEdit.end_date;
      this.editSubscription.type = subscriptionToEdit.type;
      this.editSubscription.status = subscriptionToEdit.status;
      this.editSubscription.invoice_number = subscriptionToEdit.invoice_number;

      // Set input fields
      this.$refs.updateSubscriptionStartDate.setValue(
        this.editSubscription.start_date
      );
      this.$refs.updateSubscriptionEndDate.setValue(
        this.editSubscription.end_date
      );
      this.$refs.updateSubscriptionType.setValue(this.editSubscription.type);
      this.$refs.updateSubscriptionStatus.setValue(
        this.editSubscription.status
      );
      this.$refs.updateSubscriptionInvoice.setValue(
        this.editSubscription.invoice_number
      );

      this.editMode = true;
    },
    closeEditMode() {
      this.editSubscription.id = "";
      this.editSubscription.start_date = "";
      this.editSubscription.end_date = "";
      this.editSubscription.type = "";
      this.editSubscription.status = "";
      this.editSubscription.invoice_number = "";
      this.editMode = false;
    },
    createNewSubscription() {
      const newSubscription = {
        months: this.newSubscription.months,
        status: this.newSubscription.status,
        type: this.newSubscription.type,
        invoiceNumber: this.newSubscription.invoiceNumber,
      };

      const userId = this.$props.user.id;

      AngelaAPI.createSubscription(userId, newSubscription)
        .then((response) => {
          EventBus.$emit("refresh-users");
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    updateSubscription() {
      AngelaAPI.updateSubscription(
        this.editSubscription.id,
        this.editSubscription
      )
        .then((response) => {
          this.editMode = false;
          EventBus.$emit("refresh-users");
        })
        .catch((error) => {
          // console.log(error.response);
        });
    },
    // CLASSES
    statusClasses(status) {
      return {
        "subscription-status": true,
        "subscription-status-pending": status === "pending",
        "subscription-status-paid": status === "paid",
        "subscription-status-active": status === "active",
      };
    },
    editModeClasses() {
      return {
        "lightbox-subscription-edit": true,
        "lightbox-subscription-edit-show": this.editMode,
      };
    },
  },
};
</script>

<style lang="scss">
.lightbox-subscription {
  &-edit {
    display: none;
    &-show {
      display: block;
    }

    &-tools {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &-create {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;

    .form-select-input,
    .form-datepicker-input {
      margin-right: 15px;
    }
  }

  table {
    width: 100%;
    thead {
      cursor: pointer;
      position: relative;

      tr {
        th {
          color: #979797;
          font-size: 0.625rem;
          font-weight: 300;
          padding-bottom: 15px;
          position: relative;

          img {
            margin: 0px 0px 3px 2px;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 15px 0px;
          border-bottom: 1px solid #d8d8d8;

          &.tools {
            button {
              font-size: 25px;
              margin: 0px 5px;
              opacity: 0.5;

              &.tools-pending {
                svg {
                  path {
                    fill: #ff1e1e;
                  }
                }
              }

              &.tools-paid {
                svg {
                  path {
                    fill: #50ae22;
                  }
                }
              }

              &:hover {
                opacity: 1;
              }
            }
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            text-align: center;
          }
        }
      }
    }
  }

  .subscription-status {
    padding: 5px 10px;
    border-radius: 25px;
    color: #fff;
    position: relative;

    &-pending {
      background-color: #ff1e1e;
    }
    &-paid {
      background-color: #50ae22;
    }
  }

  .table-sorting {
    display: none;

    &-show {
      display: inline-block;
    }

    &-asc {
      transform: rotate(180deg);
    }
  }
}
</style>
