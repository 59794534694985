<template>
  <div class="form-select-input">

        <label
            v-show="inputLabel != ''"
            :for="getInputId()">{{ inputLabel }}</label>
        
        <select
            :id="getInputId()"
            @change="updateValue"
            v-model="selected">
            <option disabled value="">{{ placeholder }} </option>
            <option 
                v-for="(option, index) in options" 
                :key="index" 
                :value="option.val"
                :selected="selectedOption(option.val)">
                {{ option.name }}
            </option>
        </select>

        <img :class="arrowClasses()" src="../../assets/images/select_arrow.svg" />
        
        <p v-show="(errorMessage != '')">{{ errorMessage }}</p>

  </div>
</template>

<script>
export default {
    name: 'SelectInput',
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        inputLabel: {
            type: String,
            default: ''
        },
        inputName: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            default: () => []
        }
    },
    data(){
      return {
          errorMessage: '',
          selected: ''
      };
    },
    methods: {
        getInputId(){
            return `text-input-${this.$props.inputName}`
        },
        selectedOption(val){
            return this.selected === val;
        },
        updateValue(event){
            this.selected = event.target.value;
            this.$emit('input', event.target.value)
        },
        setError(message){
            this.errorMessage = message;
        },
        setValue(val){
            this.selected = val;
        },
        resetValue(){
            this.selected = '';
        },
        arrowClasses(){
            return {
                'form-select-input-arrow': true,
                'form-select-input-arrow-with-label': (this.$props.inputLabel !== '') 
            }
        }
    }
}
</script>

<style lang="scss">

.form-select-input{
    width: 100%;
    margin-top: 10px;
    position: relative;

    p{
        margin-top: 5px;
        margin-bottom: 15px;
        color: red;
    }

    label{
        font-weight: 450;
        font-size: 0.875rem;
    }

    select{
        width: 100%;
        height: 40px;
        margin-top: 5px;
        padding: 12px 13px;
        background-color: #F3F3F3;
        border-radius: 10px;
        font-size: 0.75rem;
    }

    &-arrow{
        position: absolute;
        right: 14px;
        top: 23px;

        &-with-label{
            top: 42px;
        }
    }
}

</style>
